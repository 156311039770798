//core
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
//front10 components
import "@front10/landing-page-book/dist/themes/default/index.legacy_browsers.css";
import {front10} from "../../assets/images/logo/front10.png";
import "../app.css";

import React from "react";

import {
  Row,
  Header,
  Container,
  Hero,
  Image,
  Link,
  GithubButton,
  Column,
  Navbar,
  NavbarCollapse,
  NavbarBrand,
  NavbarNav,
  NavbarLink,
  Icon,
  LanguageSwitcher,
  Features,
  Team,
  BrowserSupport,
  Gif,
  Footer,
  Copyright,
  Social
} from "@front10/landing-page-book/dist/components";

import Helmet from "react-helmet";

import particles from "../data/particles.json";
import features from "../data/features.json";
import team from "../data/team.json";
import browsers from "../data/browsers.json";

import logo from "../favicon.png";

export default () => (
  <div className="App">
    <Helmet defaultTitle={`Landing Page Book | Gatsby js`}>
      <link rel="shortcut icon" href={logo} />
      <script src="https://cdn.polyfill.io/v2/polyfill.js?features=default,Symbol" />
    </Helmet>
    <Container>
      <Row className="mt-5">
        <Column>
          <Navbar expand="md">
            <NavbarCollapse>
              <NavbarBrand>
                <Image
                  alt="Front10 logo"
                  src={front10}
                  width="40"
                />
              </NavbarBrand>
              <NavbarNav alignItems="right">
                <NavbarLink>
                  <Icon icon="fa fa-search" />
                </NavbarLink>
                <NavbarLink>
                  <Icon icon="fa fa-github" />
                </NavbarLink>
                <NavbarLink>
                  <Icon icon="fa fa-stack-overflow" />
                </NavbarLink>
                <LanguageSwitcher showArrow={false} />
              </NavbarNav>
            </NavbarCollapse>
          </Navbar>
        </Column>
      </Row>
      <Row className="mt-0">
        <Column>
          <Hero
            backgroundColor="#212529"
            particlesParams={particles}
            minHeight="50vh"
          >
            <Container>
              <Image
                alt="Front10 logo"
                className="main-logo"
                rounded
                src={front10}
                width="80"
              />
              <Header className="text-warning"> $> whatsup.sh</Header>
              <Header className="text-warning" type="h5">
                Monitor up/down status of your endpoints!
              </Header>
              <div className="mt-5">
                <Link
                  className="btn btn-primary btn-started"
                  href="#"
                  target="_blank"
                >
                  Explore
                </Link>
                <GithubButton btnText="Stars" btnType="star" />
              </div>
            </Container>
          </Hero>
        </Column>
      </Row>
      <Row className="mt-5">
        <Column>
          <Header type="h5">3. Features</Header>
          <Features
            features={features}
            imageCircle={false}
            showBorder={false}
            showSubtitle={true}
          />
        </Column>
      </Row>
      <Row className="mt-5">
        <Column>
          <Header type="h5">4. Team</Header>
          <Team
            showBorder={false}
            members={team}
            socials={["linkedin", "twitter"]}
          />
        </Column>
      </Row>
      <Row className="mt-5">
        <Column>
          <Header type="h5">Whats Up?</Header>
          <Gif autoplay image="images/demo/whatsup.gif" />
        </Column>
      </Row>
      <Row className="mt-5 mb-5">
        <Column className="text-center">
          <Link
            className="btn btn-primary btn-started"
            href="https://www.foretheta.com/contact/"
            target="_blank"
          >
            Contact us
          </Link>
        </Column>
      </Row>
    </Container>
  </div>
);
